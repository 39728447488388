.Terms {
  background-color: #f8fafc;
  min-height: 100vh;
}

.Terms-header {
  background-color: #2563eb;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.Terms-header img {
  height: 80px;
  margin-bottom: 20px;
}

.Terms-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: 700;
}

.Terms-last-updated {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}

.Terms-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: -20px;
  margin-bottom: 40px;
}

.Terms-notice {
  background-color: #f8fafc;
  border-left: 4px solid #2563eb;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 4px;
}

.Terms-notice p {
  margin: 0;
  font-weight: 500;
  color: #1e293b;
}

.Terms-content h2 {
  color: #1e293b;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 700;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 10px;
}

.Terms-content h3 {
  color: #334155;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 1.4rem;
  font-weight: 600;
}

.Terms-content p {
  color: #475569;
  line-height: 1.7;
  margin-bottom: 1.5rem;
  font-size: 1.05rem;
}

.Terms-content ul {
  margin-bottom: 1.5rem;
  padding-left: 20px;
}

.Terms-content li {
  color: #475569;
  margin-bottom: 0.75rem;
  line-height: 1.6;
  font-size: 1.05rem;
}

.Terms-definitions p {
  margin-bottom: 1rem;
}

.Terms-definitions strong {
  color: #1e293b;
  font-weight: 600;
}

@media (max-width: 768px) {
  .Terms-content {
    padding: 20px;
    margin: 0;
    border-radius: 0;
  }
  
  .Terms-header h1 {
    font-size: 2rem;
  }
  
  .Terms-content h2 {
    font-size: 1.5rem;
  }
  
  .Terms-content h3 {
    font-size: 1.3rem;
  }
  
  .Terms-content p,
  .Terms-content li {
    font-size: 1rem;
  }
}

@media print {
  .Terms {
    background: white;
  }
  
  .Terms-content {
    box-shadow: none;
    margin: 0;
    padding: 20px;
  }
}