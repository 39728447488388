.Privacy {
    background-color: #f8fafc;
    min-height: 100vh;
  }
  
  .Privacy-header {
    background-color: #2563eb;
    color: white;
    padding: 40px 20px;
    text-align: center;
  }
  
  .Privacy-header img {
    height: 80px;
    margin-bottom: 20px;
  }
  
  .Privacy-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .Privacy-last-updated {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
  }
  
  .Privacy-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: -20px;
    margin-bottom: 40px;
  }
  
  .Privacy-content h2 {
    color: #1e293b;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: 700;
    border-bottom: 2px solid #e2e8f0;
    padding-bottom: 10px;
  }
  
  .Privacy-content h3 {
    color: #334155;
    margin-top: 30px;
    margin-bottom: 15px;
    font-size: 1.4rem;
    font-weight: 600;
  }
  
  .Privacy-content p {
    color: #475569;
    line-height: 1.7;
    margin-bottom: 1.5rem;
    font-size: 1.05rem;
  }
  
  .Privacy-content ul {
    margin-bottom: 1.5rem;
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .Privacy-content li {
    color: #475569;
    margin-bottom: 0.75rem;
    line-height: 1.6;
    font-size: 1.05rem;
  }
  
  .Privacy-content strong {
    color: #334155;
    font-weight: 600;
  }
  
  @media (max-width: 768px) {
    .Privacy-content {
      padding: 20px;
      margin: 0;
      border-radius: 0;
    }
    
    .Privacy-header h1 {
      font-size: 2rem;
    }
    
    .Privacy-content h2 {
      font-size: 1.5rem;
    }
    
    .Privacy-content h3 {
      font-size: 1.3rem;
    }
    
    .Privacy-content p,
    .Privacy-content li {
      font-size: 1rem;
    }
  }
  
  @media print {
    .Privacy {
      background: white;
    }
    
    .Privacy-content {
      box-shadow: none;
      margin: 0;
      padding: 20px;
    }
  }